import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Outlet } from 'react-router-dom'
import Footer from '../components/footer/Footer'
import Overlay from '../components/global/Overlay'
import Header from '../components/header/Header'
import Sidebar from '../components/sidebar/Sidebar'

function Layout() {

  const[showMenu, setShowMenu] = useState(false)
  const[promptOpen, setPromptOpen] = useState(false)
  const [isMobile, setIsMobile] = useState(true)
 
  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 969) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }
  
  // create an event listener
  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize)
  })

  // toggle the sidebar
  let toggleSidebar = () => {
    setShowMenu(!showMenu) 
  }
  
  return (
    <div className='h-screen flex flex-col'>
      <Overlay toggleSidebar={toggleSidebar} showMenu={showMenu} />
      <Header toggleSidebar={toggleSidebar} setPromptOpen={setPromptOpen}/>
      <div className='bg-gray-50 flex flex-1 overflow-x-hidden no-scrollbar mt-11'>
        {!promptOpen && <Sidebar toggleSidebar={toggleSidebar} showMenu={showMenu} />}
        <div className={`${!isMobile ? 'pl-60 w-full': ''} p-3 flex flex-col flex-1`}>
          <div className={` flex-1`}>{<Outlet />}</div>
          <Footer />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state?.auth && state?.auth ? state?.auth : []
  };
};

export default connect(mapStateToProps)(Layout);