export const dischargeData = [
    {request_id: 14, from_id: 2, from_name: 'Frant Momanyi', request_date: '12/03/2023', discharge_status: 2},
    {request_id: 45, from_id: 4, from_name: 'Freed jose', request_date: '09/03/2023', discharge_status: 1},
    {request_id: 87, from_id: 55, from_name: 'Kiloo John', request_date: '12/03/2023', discharge_status: 2},
    {request_id: 7, from_id: 77, from_name: 'Gitton Momanyi', request_date: '12/03/2023', discharge_status: 1},
    {request_id: 8, from_id: 57, from_name: ' Mhhok Musa', request_date: '10/03/2023', discharge_status: 2},
    {request_id: 5, from_id: 55, from_name: 'Susan Momanyi', request_date: '12/03/2023', discharge_status: 1},
    {request_id: 34, from_id: 78, from_name: 'Bernad Otieno', request_date: '11/05/2023', discharge_status: 2},
    {request_id: 77, from_id: 29, from_name: 'Mkombero Mugo', request_date: '11/03/2023', discharge_status: 1},
  ]

  export const claimsData = [
    {cl_id: 'AFJSL34', national_id: '65464745', first_name: 'Clemnt', last_name: ' Maina', settled_at: '12/12/2023', h_b: '5000'},
    {cl_id: 'GSDFFGG3', national_id: '34653456', first_name: 'Nancy', last_name: ' Fred', settled_at: '06/12/2023', h_b: '7000'},
    {cl_id: 'RTYRTY34', national_id: '9665787', first_name: 'Bernad', last_name: ' Julius', settled_at: '01/12/2023', h_b: '8000'},
    {cl_id: 'NJHDKJ45', national_id: '44663466', first_name: 'Gitton', last_name: ' Jojo', settled_at: '06/12/2023', h_b: '900'},
    {cl_id: 'KYSHGJL6', national_id: '34523434', first_name: 'Moses', last_name: ' Lian', settled_at: '09/12/2023', h_b: '5600'},
    {cl_id: 'KYSHGJL77', national_id: '34523434', first_name: 'Kinara', last_name: ' Kilion', settled_at: '11/12/2023', h_b: '5600'},
    {cl_id: 'KYSHGJL5', national_id: '34523434', first_name: 'Kingstone', last_name: ' Hillary', settled_at: '03/12/2023', h_b: '5600'},
    {cl_id: 'KYSHGJ34', national_id: '34523434', first_name: 'Oloo', last_name: ' Nana', settled_at: '04/12/2023', h_b: '5600'},
    {cl_id: 'KYSHGJ84', national_id: '34523434', first_name: 'Mkombero', last_name: ' Susan', settled_at: '08/12/2023', h_b: '5600'},
    {cl_id: 'KYSHGJL26', national_id: '34523434', first_name: 'Mugo', last_name: ' Huto', settled_at: '03/12/2023', h_b: '5600'},
    {cl_id: 'KYSHGJ146', national_id: '34523434', first_name: 'Njuguna', last_name: ' Brian', settled_at: '04/12/2023', h_b: '5600'},
    {cl_id: 'KYSHGJL4', national_id: '34523434', first_name: 'Onchwari', last_name: ' Kevin', settled_at: '04/12/2023', h_b: '5600'},
    {cl_id: 'KYSHGJg26', national_id: '34523434', first_name: 'Knana', last_name: ' Mtiso', settled_at: '04/12/2023', h_b: '5600'},
    {cl_id: 'KYSHG3567', national_id: '34523434', first_name: 'Msaafe', last_name: ' Jyson', settled_at: '04/12/2023', h_b: '5600'},
    {cl_id: 'KYSHGJgk', national_id: '34523434', first_name: 'George', last_name: ' Nuru', settled_at: '04/12/2023', h_b: '5600'},
    {cl_id: 'KYSHG254', national_id: '34523434', first_name: 'Kalena', last_name: ' Kamu', settled_at: '04/12/2023', h_b: '5600'},
    {cl_id: 'KYSHGJL85', national_id: '34523434', first_name: 'Bruce', last_name: ' Njuguna', settled_at: '04/12/2023', h_b: '5600'},
    {cl_id: 'KYSHGJURR', national_id: '34523434', first_name: 'Diana', last_name: ' Moris', settled_at: '04/12/2023', h_b: '5600'},
    {cl_id: 'KYSHGJ879', national_id: '34523434', first_name: 'Molte', last_name: ' Obama', settled_at: '04/12/2023', h_b: '5600'},
  ]

  export const priceCategories = [
    {item_code: 'FJKJGG88', item_desc: 'Some desc here', unit_price: 300, discounted_price: 280, final_price: 200, created_at: '04/12/2022', updated_at: '03/01/2023'},
    {item_code: 'SFFGFDG', item_desc: 'Some desc here', unit_price: 300, discounted_price: 280, final_price: 200, created_at: '04/12/2022', updated_at: '03/01/2023'},
    {item_code: 'FGHGFHEE', item_desc: 'Some desc here', unit_price: 300, discounted_price: 280, final_price: 200, created_at: '04/12/2022', updated_at: '03/01/2023'},
    {item_code: 'GTDBJUF5', item_desc: 'Some desc here', unit_price: 300, discounted_price: 280, final_price: 200, created_at: '04/12/2022', updated_at: '03/01/2023'},
  ]