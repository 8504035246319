import React from 'react'

function DashboardCard({title, num, icon}) {
  return (
    <div className='w-1/4 h-24 border border-gray-200 rounded-lg grow shadow-sm sm:w-full md:w-1/2'>
        <div className="flex space-x-3 items-center justify-between px-4 h-full">
          <div className='flex-col flex-1 space-y-2' >
              <h1 className='text-lg font-bold text-gray-400'>{num}</h1>
              <h1 className='text-[11px] text-gray-500'>{title}</h1>
          </div>
          <div className='text-4xl font-bold text-gray-400'>{icon}</div>
        </div>
    </div>
  )
}

export default DashboardCard