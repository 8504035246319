import React from "react";

const AuthInput = (props) => {
  return (
    <div>
      <div className="w-full bg-white flex px-4 py-[10px] rounded-full space-x-2 align-middle mb-6">
        <span>
        { props.prefix && props.prefix }
        </span>
        <input
          className="bg-transparent flex-1 outline-none"
          name={props.name}
          type={props.type}
          value={props.value}
          onChange={props.onChange}
          required={props.required}
          autoComplete="off"
          placeholder={props.placeholder}
        />
        <span>
        { props.suffix && props.suffix }
        </span>
      </div>
    </div>
  );
};

export default AuthInput;
