// list all the menu items here
export const menuItems = [
    {
        key: 'dashboard',
        label: 'Dashboard',
        path: '/home',
        icon: <i className="bx bx-home-alt"></i>,
    },
    {
        key: 'claims',
        label: 'Claims',
        path: '/claims/list',
        icon: <i className="bx bx-file"></i>,
    },
    {
        key: 'price-categories',
        label: 'Price categories',
        path: '/price-categories/list',
        icon: <i className="bx bx-category-alt"></i>,
    },
    {
        key: 'authorization',
        label: 'Authorization',
        path: '/authorization/requests',
        icon: <i className="bx bx-pen"></i>,
    },
    {
        key: 'discharge-summary',
        label: 'Discharge summary',
        path: '/discharge-summary/summary',
        icon: <i className="bx bx-food-menu"></i>,
    }
]

export const bottomMenu = [    
    {
        key: 'profile',
        label: 'Profile',
        path: '/profile',
        icon: <i className="bx bx-user-circle"></i>,
    },
    {
        key: 'notifications',
        label: 'Notifications',
        path: '/notifications',
        icon: <i className="bx bx-bell"></i>,
    }
]