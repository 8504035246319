import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import bgImg from '../assets/images/bg.jpg'
import AuthInput from '../components/global/AuthInput'
import Button from '../components/global/Button'
import CheckBox from '../components/global/CheckBox'

function Auth() {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [isPassword, setIsPassword] = useState(true);
    let togglePassword = ()=> {
      setIsPassword(!isPassword)
    }
  
    const [showLoginForm, setShowLoginForm] = useState(true)
  
    let toggleForms = () =>{
      setShowLoginForm(!showLoginForm)
    }

    let [state, setState] = useState({
        email: "",
        password: "",
      });
    
      let handleChange = (e) => {
        const value = e.target.value;
        setState({ ...state, [e.target.name]: value });
      };

    //   execute login process
      let login = () =>{
        setLoading(true)
        if(state.email && state.password){
            setLoading(false)
            navigate('home')
        }
        else
        {
            setLoading(false)
        }
      }

    //   send otp from forgotpass form
      let setOtpForm = () =>{
        setLoading(true)
        if(state.email && state.password){
            setLoading(false)
        }
        else
        {
            setLoading(false)
        }
      }
  
  return (
    <div style={{ backgroundImage: `url(${bgImg})`, backgroundRepeat: "no-repeat", backgroundSize: "cover"}} className='flex-1 h-screen'>
        <div className="flex bg-primary bg-opacity-50 top-[5%] bottom-[5%] left-[5%] w-[90%] absolute rounded-3xl p-8">
            {/* right side container */}
            <div className="sm:hidden md:hidden w-1/2 items-center flex justify-center">
                <div className='flex flex-col items-center justify-center'>
                    <h1 className='text-white text-6xl font-bold'>BimaGram</h1>
                    <div className='flex space-x-2 bg-white my-2 p-2 rounded-xl'>
                        <h1 className='text-tertiary text-base font-bold'>Health.</h1>
                        <h1 className='text-secondary text-base font-bold'>Life.</h1>
                        <h1 className='text-primary text-base font-bold'>Trust.</h1>
                    </div>                
                </div>
            </div>
            {/* end of eft container */}
            
            {/* right side container */}
            <div className=" py-[25%] flex-col flex justify-center items-center w-1/2 sm:w-full md:w-full sm:border-l-0 md:border-l-0 border-l border-l-secondary">
                
                {/* logo to be displayed on small devices only */}
                <div className='hidden sm:flex md:flex flex-col items-center justify-center mb-6 border-b border-secondary'>
                    <h1 className='text-white text-6xl font-bold'>BimaGram</h1>
                    <div className='flex space-x-2 bg-white my-2 p-2 rounded-xl mb-4'>
                        <h1 className='text-tertiary text-base font-bold'>Health.</h1>
                        <h1 className='text-secondary text-base font-bold'>Life.</h1>
                        <h1 className='text-primary text-base font-bold'>Trust.</h1>
                    </div>                
                </div>

                {/* body of the form */}
                {/* title */}
                <div className="my-6 m-auto flex justify-center">
                    <h3 className="text-3xl sm:text-xl text-white font-bold">{showLoginForm ? "Sign In" : "Forgot Password"}</h3>
                </div>

                {/* form */}
                { showLoginForm && 
                    <form onSubmit={login}>
                        <div className="px-8 w-96 sm:w-full m-auto">
                        {/* input fields */}
                        <AuthInput
                            prefix={<i className="bx bxs-user-circle text-2xl text-gray-300"></i>}
                            name="email"
                            type="email"
                            value={state.email}
                            onChange={handleChange}
                            required={true}
                            placeholder="Email"
                        />
                        <AuthInput
                            prefix={<i className="bx bxs-lock text-2xl text-gray-300"></i>}
                            name="password"
                            type={isPassword ? "password" : "text"}
                            value={state.password}
                            onChange={handleChange}
                            required={true}
                            placeholder="Password"
                        />  
                        <CheckBox value="0" title="Show password" onChange={togglePassword} checked={isPassword} />
                        </div>
            
                        {/* action button */}
                        <div className="justify-center flex">
                        {<Button
                            class="bg-secondary px-8 py-2 rounded-full text-white font-bold"
                            type="submit"
                            text={loading ? "Signing in..." : "Sign in"}
                            disabled={loading}
                        />}
                        </div>
                    </form>
                }

                {/* reset password form */}
                { !showLoginForm && 
                    <form onSubmit={setOtpForm}>
                        <div className="px-8 w-96 sm:w-full m-auto">
                            <p className="text-sm text-white mb-4">Enter your email so that we send you a One Time Password (OTP).</p>
                            {/* input fields */}
                            <AuthInput
                            prefix={<i className="bx bxs-user-circle text-2xl text-gray-300"></i>}
                            name="email"
                            type="email"
                            value={state.email}
                            onChange={handleChange}
                            required={true}
                            placeholder="Email"
                            />
                        </div>
                        
                        {/* action button */}
                        <div className="justify-center flex">
                            {<Button
                                class="bg-secondary px-8 py-2 rounded-full text-white font-bold"
                                type="submit"
                                text={loading ? "Requesting..." : "Send OTP"}
                                disabled={loading}
                            />}
                        </div>
                    </form>
                }
                <div className="flex mt-3 m-auto justify-center">
                    <p onClick={toggleForms} className="text-white cursor-pointer">{showLoginForm ? "Forgot password?" : "Back to login page"}</p>
                </div>      
            </div>
        </div>
    </div>
  )
}

export default Auth