import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  
  export const options = {
    responsive: true,
    scales: {
      x: {
  
        drawBorder: true, // <-- this removes y-axis line
        lineWidth: 0.5,
        grid: {
          display: false,
        }
      },
      y: {
        grid: {
          display: true
        },
        beginAtZero: true
      }
    },
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false,
      },
    },
    
  };
  const revenueByMonths =  {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    data: [250, 200, 300, 280, 100, 220, 310, 190, 200, 120, 250, 350],
  }
  
  const chartData = {
      labels: revenueByMonths.labels,
      datasets: [
        {
          label: "Revenues",
          data: revenueByMonths.data,
          lineTension: 0.4
        },
      ],
    };
   

function MonthlyRevenue() {       
  return (
    <div className=''>
        <Line options={options} data={chartData} height={`100px`} />
    </div>
  )
}

export default MonthlyRevenue