import React from 'react'
import HeaderText from '../global/HeaderText'
import Loader from '../global/Loader';
import DataTable from "../../components/datatable/DataTable";
import { priceCategories } from '../../constants/data';

function List() {
    const headers = ["Item code", "Item description", "Unit price", "Discounted price",  "Final price", "Edit"];
    let tableRows = [];
    if (priceCategories.length) {
        for (let i = 0; i < priceCategories.length; i++) {
        tableRows.push({
            item_code: priceCategories[i].item_code,
            item_desc: priceCategories[i].item_desc,
            unit_price: priceCategories[i].unit_price,
            discounted_price: priceCategories[i].discounted_price,            
            final_price: priceCategories[i].final_price,                  
            edit: (
            <i className="bx bx-edit links text-[16px] cursor-pointer"
                onClick={() => addClientBill(priceCategories[i].item_code)}
            ></i>
            ),
        });
        }
    }

  const addClientBill = (id) => {
    const claim = priceCategories.find((x) => x.item_code === id);
    console.log(claim);
  };

  const displayContent = priceCategories ? (
    !priceCategories.length ? (
      <Loader />
    ) : (
      <DataTable headers={headers} data={tableRows} />
    )
  ) : (
    <Loader />
  );
  return (
    <div>
        <HeaderText title='Price categories' />
        { displayContent }
    </div>
  )
}

export default List