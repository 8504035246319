import React from 'react'

function PromptModal({setPromptOpen, open, setOpenModal, action, title}) {
    let onClose = () => {
        setPromptOpen(false)
        setOpenModal(false)
    }
  return (
    <div className={`${open ? 'flex' : 'hidden'} bg-primary opacity-90 fixed h-screen w-screen bottom-0 justify-center items-center left-0`}>
        <div className="flex flex-col h-40 w-96 bg-white bg-opacity-1 px-4 py-2 absolute rounded-2xl ease-in-out duration-700 sm:w-[90%]">
            <div className='text-gray-500 justify-end flex '>
                <i onClick={onClose} className='bx bx-x cursor-pointer text-2xl hover:bg-gray-300 hover:rounded-full'></i>
            </div>
            <div className='flex-1 items-center justify-center flex font-semibold'>{title}</div>
            <div className='flex justify-between text-white font-semibold mb-2'>
                <button onClick={onClose} className='px-4 py-2 bg-red-700 rounded-lg'>Cancel</button>
                <button onClick={action} className='px-4 py-2 bg-green-700 rounded-lg'>Continue</button>
            </div>
        </div>
    </div>
  )
}

export default PromptModal