//
//      Combime all the reducers and export as one to the store.js
//
import { combineReducers } from "redux";
import authReducer from "./auth/authReducer";
import sidebarReducer from "./sidebarMenu/sidebarReducer"

export const rootReducer = combineReducers({
  auth: authReducer,
  sidebar: sidebarReducer
});

export default rootReducer;
