import React, { useEffect, useMemo, useState } from "react";
import SearchInput from "../global/SearchInput";
import img from "../../assets/images/no-data-found.png";
import Pagination from "./Pagination";
import SelectInput from "../global/SelectInput";

function DataTable({ headers, data }) {
  let [PageSize, setPageSize] = useState(10);
  // new configs /////////////////////////
  const [currentPage, setCurrentPage] = useState(1);
  let options = [
    { value: 10, label: "10 records" },
    { value: 25, label: "25 records" },
    { value: 50, label: "50 records" },
    { value: data.length, label: "All records" },
  ];
  const [show_num_of_records, setShowNumOfRecords] = useState(10);

  //   handle select field change
  const handleChange = (e) => {
    const val = e.target.value;
    setPageSize(val);
    setShowNumOfRecords(val);
  };

  let currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, PageSize, data]);

  useEffect(() => {
    setPageSize(PageSize);
  }, [PageSize]);

  // new configs /////////////////////////

  const searchData = (e) => {
    let rows = document.querySelectorAll("table > tbody > tr");
    let filter = e.target.value.toLowerCase().trim();
    // Loop through first tbody's rows
    for (let i = 0; i < rows.length; i++) {
      // define the row's cells
      let tds = rows[i].getElementsByTagName("td");

      // hide the row
      rows[i].style.display = "none";

      // loop through row cells
      for (let j = 0; j < tds.length; j++) {
        // if there's a match
        if (tds[j].innerHTML.toLowerCase().indexOf(filter) > -1) {
          // show the row
          rows[i].style.display = "";

          // skip to the next row
          continue;
        }
      }
    }
  };

  const dataTable = currentTableData.length ? (
    currentTableData.map((row, index) => {
      let rowData = [];
      let i = 0;

      for (const key in row) {
        rowData.push({
          key: headers[i],
          val: row[key],
        });
        i++;
      }
      return (
        <tr className="border-b border-gray-200 hover:bg-red-50 sm:border sm:shadow-md sm:grid sm:divide-y sm:divide-gray-200 sm:bg-white sm:rounded-xl " key={index}>
          {rowData.map((data, index) => (
            <td
              className={`py-2 sm:py-3 px-3 text-[12px] tracking-wide text-gray-600 before:font-semibold sm:flex sm:before:justify-between sm:basis sm:before:basis-1/2 sm:before:content-[attr(data-label)] sm:flexba`}
              key={index}
              data-label={data.key}
              data-heading={data.key}
            >
              {data.val}
            </td>
          ))}
        </tr>
      );
    })
  ) : (
    <tr className="w-full">
      <td colSpan={Object.keys(headers).length}>No records found</td>
    </tr>
  );
  return (
    <div className=" bg-white py-3 sm:bg-transparent border border-gray-200 sm:border-none min-h-[200px] rounded-lg shadow-sm">
      <div hidden={data.length ? "" : "hidden"}>
        <div className={`${currentTableData.length < 6 ? "hidden" : "flex justify-between mt-2 sm:grid sm:justify-evenly sm:space-x-0 space-x-4 border-b sm:border-none border-gray-200 p-3 sm:p-0 sm:my-3"}`}>
            <div className="w-full">
              <SelectInput
                label="Show"
                onChange={handleChange}
                options={options}
                name="show_num_of_records"
                value={show_num_of_records}
              />
            </div>
          <div className="w-full">
            <SearchInput search={searchData} />
          </div>
        </div>
        <div className="px-2 sm:px-0 mb-4">
          <table className="w-full">
            <thead className="border-b border-gray-100 sm:hidden">
              <tr className="">
                {headers.map((col, index) => (
                  <th className="py-2 px-3 text-[12px] font-semibold tracking-wide text-left text-gray-500" key={index}>
                    {col}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="sm:space-y-2">{dataTable}</tbody>
          </table>
        </div>
        <div className={`${currentTableData.length < 6 ? 'hidden' : 'block'} flex justify-between px-5 py-3`}>
          <div className={`text-sm text-gray-600`}>
            Total records: {data.length}
          </div>
          <div className="flex justify-between">
            <Pagination
              className="flex justify-between space-x-3"
              currentPage={currentPage}
              totalCount={data.length}
              pageSize={PageSize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      </div>
      <div className="no-data" hidden={currentTableData.length ? "hidden" : ""}>
        <img src={img} alt="" />
      </div>
    </div>
  );
}

export default DataTable;
