import React from 'react'

function Footer() {
  return (
    <div className='w-full items-center justify-center flex p-4'>
        <h1 className='text-gray-600 text-[11px] italic'>&copy;{new Date().getFullYear()}, Bimagram Insurance Agency</h1>
    </div>
  )
}

export default Footer