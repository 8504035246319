import React from 'react'
import HeaderText from '../global/HeaderText'
import Loader from '../global/Loader';
import DataTable from "../../components/datatable/DataTable";
import { dischargeData } from '../../constants/data';
import FullDateForrmat from '../global/FullDateForrmat';

function Summary() {
    const headers = ["Request ID", "Patients' ID", "Patient",  "Date requested", "Upload PDF"];
    let tableRows = [];
    if (dischargeData.length) {
        for (let i = 0; i < dischargeData.length; i++) {
        tableRows.push({
            request_id: dischargeData[i].request_id,
            from_id: dischargeData[i].from_id,
            from_name: dischargeData[i].from_name,
            date_created: (
                <FullDateForrmat>
                    {dischargeData[i].request_date}
                </FullDateForrmat>
            ),
            action: (
            <i className="bx bx-link-external links text-[16px] cursor-pointer"
                onClick={() => addClientBill(dischargeData[i].request_id)}
            ></i>
            ),
        });
        }
    }

  const addClientBill = (id) => {
    const claim = dischargeData.find((x) => x.request_id === id);
    console.log(claim);
  };

  const displayContent = dischargeData ? (
    !dischargeData.length ? (
      <Loader />
    ) : (
      <DataTable headers={headers} data={tableRows} />
    )
  ) : (
    <Loader />
  );
  return (
    <div>
        <HeaderText title='Discharge Summary' />
        { displayContent }
    </div>
  )
}

export default Summary