import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Layout from './layout/Layout'
import Auth from './pages/Auth'
import Claims from './pages/Claims'
import Dashboard from './pages/Dashboard'
import DischargeSummary from './pages/DischargeSummary'
import PriceCategories from './pages/PriceCategories'
import Authorization from './pages/Authorization'
import Profile from './pages/Profile'
import ClaimsList from './components/claims/ClaimsList'
import List from './components/priceCategories/List'
import Requests from './components/authorization/Requests'
import Summary from './components/discharge/Summary'
import Notifications from './pages/Notifications'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />}>
          {/* all protected routed here */}
          <Route path='home' element={<Dashboard />} />
          <Route path='claims' element={<Claims />}>
            <Route path='list' element={<ClaimsList />} />
          </Route>
          <Route path='price-categories' element={<PriceCategories />}>
            <Route path='list' element={<List />} />
          </Route>
          <Route path='authorization' element={<Authorization />}>
            <Route path='requests' element={<Requests />} />
          </Route>
          <Route path='discharge-summary' element={<DischargeSummary />}>
            <Route path='summary' element={<Summary />} />
          </Route>
          <Route path='notifications' element={<Notifications />} />
          <Route path='profile' element={<Profile />} />
        </Route>
        {/* list the public routes */}
        <Route index path='/' element={<Auth />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App