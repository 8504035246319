import React, { useEffect, useState } from 'react'

function Overlay({showMenu, toggleSidebar}) {
    const [isMobile, setIsMobile] = useState(true)
 
    //choose the screen size 
    const handleResize = () => {
      if (window.innerWidth < 969) {
          setIsMobile(true)
      } else {
          setIsMobile(false)
      }
    }
    
    // create an event listener
    useEffect(() => {
      handleResize()
      window.addEventListener("resize", handleResize)
    })
  return (
    <div onClick={()=> toggleSidebar()} className={`${isMobile && showMenu ? 'flex': 'hidden'} h-screen bg-primary opacity-50 absolute z-10 w-screen`}></div>
  )
}

export default Overlay