import React from 'react'

function HeaderText({title}) {
  return (
    <div className='mb-2'>
        <h1 className='text-primary font-bold text-lg'>{title}</h1>
    </div>
  )
}

export default HeaderText