import React from 'react'

function CheckBox(props) {
  return (
    <div className="flex mb-4 -mt-4 ml-6">
        <label className='cursor-pointer text-white' >
            <input onChange={props.onChange} checked={props.isChecked} className="mr-2" type="checkbox" />
            {props.title}
        </label>
    </div>  
  )
}

export default CheckBox