import React from 'react'
import HeaderText from '../global/HeaderText'
import StandardDate from '../global/StandardDate';
import Loader from '../global/Loader';
import DataTable from "../../components/datatable/DataTable";
import { claimsData } from '../../constants/data';

function ClaimsList() {
    const headers = ["Claim ID", "Patients' ID", "Patient", "Bill",  "Date Claimed", "Update Bill"];
    let tableRows = [];
    if (claimsData.length) {
        for (let i = 0; i < claimsData.length; i++) {
        tableRows.push({
            client_id: claimsData[i].cl_id,
            patient_id: claimsData[i].national_id,
            patient: claimsData[i].first_name+" "+ claimsData[i].last_name,
            bill: claimsData[i].h_b,
            date_created: (
           <StandardDate>
                {claimsData[i].settled_at}
            </StandardDate>
            ),
            details: (
            <i
                className="bx bx-link-external links text-[16px] cursor-pointer"
                onClick={() => addClientBill(claimsData[i].id)}
            ></i>
            ),
        });
        }
    }

  const addClientBill = (id) => {
    const claim = claimsData.find((x) => x.id === id);
    console.log(claim);
  };

  const displayContent = claimsData ? (
    !claimsData.length ? (
      <Loader />
    ) : (
      <DataTable headers={headers} data={tableRows} />
    )
  ) : (
    <Loader />
  );
  return (
    <div>
        <HeaderText title='Claims' />
        { displayContent }
    </div>
  )
}

export default ClaimsList