import storage from "redux-persist/lib/storage";
import rootReducer from "../ReducerRoutes";
import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  REFRESH_TOKEN,
  REGISTER_FAILURE,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
} from "./authActionTypes";

// set the initial state
const initialState = () => {
  return {
    loading: false,
    userInfo: [],
    error: "",
    success: "",
    isLoggedIn: undefined,
    token: "",
  };
};

// set the reducer
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        success: "",
        error: "",
      };
    /* falls through */
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: action.payload.data.user,
        token: action.payload.data.token,
        error: "",
        success: action.payload.message,
        isLoggedIn: true,
      };
    /* falls through */
    case LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isLoggedIn: false,
      };
    /* falls through */
    case REGISTER_REQUEST:
      return {
        ...state,
        loading: true,
        success: "",
        error: "",
      };
    /* falls through */
    case REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: action.payload.data,
        token: action.payload.token,
        success: action.payload.message,
        isLoggedIn: true,
      };
    /* falls through */
    case REGISTER_FAILURE:
      return {
        ...state,
        loading: false,
        userInfo: [],
        error: action.payload,
        success: "",
        isLoggedIn: false,
      };
    /* falls through */
    case LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
        success: "",
        error: "",
      };
    /* falls through */
    case LOGOUT_SUCCESS:
      state = rootReducer(undefined, {});
      storage.removeItem("persist:LogytapAdmin");
      localStorage.removeItem("persist:LogytapAdmin");
      return {
        loading: undefined,
        success: action.payload,
        error: "",
      };
    /* falls through */
    case LOGOUT_FAILURE:
      return {
        ...state,
        loading: false,
        success: "",
        error: action.payload,
      };
    /* falls through */
    case REFRESH_TOKEN:
      return {
        ...state,
        isLoggedIn: undefined,
      };
    /* falls through */

    default:
      return state;
  }
};

export default authReducer;
