import React from "react";

const SearchInput = (props) => {
  return (
    <div className="w-full">
      <div className="w-full relative flex">
        <input
          id="search"
          type="text"
          className="w-full border-b border-gray-300 bg-transparent p-1 outline-none focus:outline-none focus:text-gray-600"
          placeholder="Search..."
          onChange={props.search}
        />
        <button type="submit" className="cursor-pointer ">
          <i className="bx bx-search"></i>
        </button>
      </div>
    </div>
  );
};

export default SearchInput;
