import React from 'react'
import { Link } from 'react-router-dom'

function DashboardSegment(props) {
  return (
    <div className="grow sm:w-full md:w-full min-h-[200px] sm:min-h-0 md:min-h-0 md:h-auto border border-gray-200 shadow-sm rounded-md py-2">
        <div className='flex justify-between border-b border-gray-200 px-4 pb-2'>
            <div className=' text-gray-400 font-semibold text-sm'>{props.header}</div>
            <Link to={props.linkTo} className='text-sm text-gray-400'>View all</Link>
        </div>
        <div className='px-4 py-1'>{props.children}</div>
    </div>
  )
}

export default DashboardSegment