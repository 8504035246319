import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { bottomMenu, menuItems } from '../../configs/SidebarNav'

function Sidebar({showMenu, toggleSidebar}) {
  const [isMobile, setIsMobile] = useState(true)
 
//choose the screen size 
const handleResize = () => {
  if (window.innerWidth < 969) {
      setIsMobile(true)
  } else {
      setIsMobile(false)
  }
}

// create an event listener
useEffect(() => {
  handleResize()
  window.addEventListener("resize", handleResize)
})

  return (
    <div className={`ease-in-out duration-1000 bg-gray-100 flex-col text-gray-700 w-56 md:w-[40%] drop-shadow-xl z-20 bottom-0 fixed top-12 overflow-y-scroll no-scrollbar
    ${isMobile ? showMenu? 'flex translate-x-0' : 'hidden translate-x-full ': 'flex'}`}>
      <div className='flex-1 flex flex-col'>        
        <div className='flex-1'>
          {menuItems.map((item, index) => (
            <SidebarItem key={index} item={item} onClick={() => toggleSidebar()} />
          ))}
        </div>
      </div>
      <div>
        <div className='border-t-2 border-t-secondary mb-4'>
          {bottomMenu.map((item, index) => (
            <SidebarItem key={index} item={item} onClick={() => toggleSidebar()} />
          ))}
        </div>
      </div>
    </div>
  )
}

const SidebarItem = ({item, onClick}) => {
  const {pathname} = useLocation()
  return (
    <Link onClick={onClick} to={item.path} className={`${pathname === item.path ? 'text-primary border-l-4 font-extrabold rounded-l-lg border-primary pl-5' : ''} flex pl-6 items-center gap-2 p-1 hover:bg-secondary hover:text-white active:bg-secondary text-base mr-2 rounded-r-full mt-1`}>
      <div className='text-2xl font-extrabold'>{item.icon}</div>
      <p className='text-sm'>{item.label}</p>
    </Link>
  )
}

export default Sidebar;