import React from 'react'
import DashboardCard from '../components/dashboard/DashboardCard'
import DashboardSegment from '../components/dashboard/DashboardSegment'
import GridWrapper from '../components/dashboard/GridWrapper'
import MonthlyRevenue from '../components/dashboard/MonthlyRevenue'
import HeaderText from '../components/global/HeaderText'
import { claimsData, dischargeData } from '../constants/data'

function Dashboard() {
  const cards = [
    {title: 'Claims today', num: 20, icon: <i className="bx bx-file"></i>},
    {title: 'Discharge requests', num: 0, icon: <i className="bx bx-file"></i>},
    {title: 'Authorization requests', num: 8, icon: <i className="bx bx-pen"></i>},
    {title: 'Monthly revenue', num: '41,000.00', icon: <i className="bx bx-food-menu"></i>},
  ]

  const claims = []
  const latestClaims = claimsData.slice(Math.max(claimsData.length - 5, 0))
  if(latestClaims){
    for (let i = 0; i < latestClaims.length; i++) {
      claims.push({
        cl_id: latestClaims[i].cl_id,
        patient: latestClaims[i].first_name +" "+ latestClaims[i].last_name,
        date_claimed: latestClaims[i].settled_at,
      })
    }
  }

  const dischargeRequests = dischargeData.slice(Math.max(dischargeData.length - 5, 0))
  const reqList = []
  if(dischargeRequests){
    for (let i = 0; i < dischargeRequests.length; i++) {
      reqList.push({
        request_id: dischargeRequests[i].request_id,
        from_name: dischargeRequests[i].from_name,
        request_date: dischargeRequests[i].request_date,
      })
    }
  }

  return (
    <div>
      <HeaderText title="Dashboard" />
      {/* cards */}
      <div className='flex space-x-2 w-full sm:grid sm:space-x-0 sm:space-y-3 md:space-x-2'>
        {cards.map((item, index) => (
          <DashboardCard key={index} title={item.title} num={item.num} icon={item.icon} />
        ))}
      </div>
      {/* display claims and authorization requests */}
      <GridWrapper>
      <DashboardSegment linkTo='/claims' header="Claims">
          {/* list latest 5 items */}
          <div className="flex justify-between py-1 border-b border-gray-200">
            <p className='text-left text-[13px] font-bold text-gray-700'>Claim ID</p>
            <p className='text-left text-[13px] font-bold text-gray-700'>Patient</p>
            <p className='text-left text-[13px] font-bold text-gray-700'>Date claimed</p>
          </div>
          <div className=" divide-y divide-gray-200">
          {
            claims.map((item, index) => (
              <div key={index} className="flex justify-between py-2 hover:bg-red-50">
                <div className='text-left text-[12px] text-gray-600'>{item.cl_id}</div>
                <div className='text-left text-[12px] text-gray-600'>{item.patient}</div>
                <div className='text-left text-[12px] text-gray-600'>{item.date_claimed}</div>
              </div>
            ))
          }
          </div>        
          
        </DashboardSegment>
        <DashboardSegment linkTo='/authorization' header="Authorization requests">
          {/* list latest 5 items */}
          <div className="flex justify-between py-1 border-b border-gray-200">
            <p className='text-[13px] font-bold text-gray-700'>Request ID</p>
            <p className='text-[13px] font-bold text-gray-700'>Patient</p>
            <p className='text-[13px] font-bold text-gray-700'>Date requested</p>
          </div>
          <div className=" divide-y divide-gray-200">
          {
            reqList.map((item, index) => (
              <div key={index} className="flex justify-between py-2 hover:bg-red-50">
                <div className='text-[12px] text-gray-600'>{item.request_id}</div>
                <div className='text-left text-[12px] text-gray-600'>{item.from_name}</div>
                <div className='text-[12px] text-gray-600'>{item.request_date}</div>
              </div>
            ))
          }
          </div>        
          
        </DashboardSegment>
      </GridWrapper>
      {/* chart */}
      <div className='w-full border-gray-200 border rounded-md shadow-sm'>
        <div className=' text-gray-400 font-semibold text-sm border-gray-200 border-b px-4 py-2'>Monthly revenue</div>
        <div className='px-4 py-2'>
          <MonthlyRevenue />
        </div>
      </div>
    </div>
  )
}

export default Dashboard