import React from "react";

const SelectInput = (props) => {
  return (
    <div className="relative w-full mb-1 ">
      <select
        name={props.name}
        className="relative bg-transparent w-1/3 sm:w-full sm:mb-2 h-9 px-1 py-2 text-sm text-gray-500 border rounded-lg border-gray-300 focus:outline-none after:text-2xl "
        required={props.required ? "required" : ""}
        value={props.value ? props.value : ""}
        onChange={props.onChange}
      >
        {props.options.map((option) => (
          <option key={option.value + option.label} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <span className="select-highlight"></span>
      <span className="select-bar"></span>
      <label className="text-gray-400 absolute cursor-none left-2 text-[14px] -top-3 sm:bg-gray-50 bg-white transition-all ease-in-out duration-700 px-1">{props.label}</label>
    </div>
  );
};

export default SelectInput;
