import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import logo from '../../assets/images/logo.png'
import PromptModal from '../global/PromptModal'

function Header({toggleSidebar, setPromptOpen}) {
  const navigate = useNavigate()
  const [showMenu, setShowMenu] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  let logout= () => {
    setShowMenu(!showMenu)
    setOpenModal(!openModal)
    setPromptOpen(true)
  }

  let logoutActions = () =>{ 
    setPromptOpen(false) 
    setOpenModal(!openModal)
    navigate('/')
  }
 
  return (
    <div className='bg-primary w-screen h-12 items-center justify-between flex flex-row p-2 fixed z-[1]'>
      
      <div className='flex flex-row'>
        <i onClick={() =>toggleSidebar()} className='bx bx-menu text-white font-bold text-xl mr-2 hidden sm:flex md:flex hover:text-secondary cursor-pointer'></i>
        <div className='bg-white w-40 h-[100%] flex items-center justify-center rounded-full'>
            <img className='w-28' src={logo} alt="" />
        </div>
      </div>
      <div className='flex flex-row justify-center items-center'>
        <i className='bx bxs-bell text-white font-bold text-xl mr-2 cursor-pointer'></i>
        <div onClick={() => setShowMenu(!showMenu)}  className='flex flex-row justify-center items-center  text-white font-bold text-3xl cursor-pointer'>
          <i className='bx bx-user-circle'></i>
          <i className='bx bx-caret-down text-xl'></i>
        </div>
      </div>
      {showMenu && <div className='bg-gray-100 py-2 shadow-md absolute z-50 top-12 w-40 right-0 divide-y divide-gray-300 text-gray-500'>
        <MenuItem onClick={() => setShowMenu(!showMenu)} title='Profile' routeTo="/profile" icon={<i className='bx bx-user-circle '></i>} />
        <MenuItem onClick={logout} title='Logout' icon={<i className='bx bx-log-out '></i>} />
      </div>}
      <PromptModal title='Your session will be ended!' action={logoutActions} setPromptOpen={setPromptOpen} open={openModal} setOpenModal={setOpenModal}/>
    </div>
  )
}

const MenuItem = ({title, routeTo, icon, onClick}) => {
  return (
    <Link onClick={onClick} to={routeTo} className='flex flex-row space-x-2 mx-3 py-2 items-center'>
      <div className='text-xl'>{icon}</div>
      <p>{title}</p>
    </Link>
  )
}

export default Header