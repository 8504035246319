import { CLOSE_SIDEBAR, OPEN_SIDEBAR } from "./actionTypes";

// set the initial state
const initialState = () => {
    return {
      menuOpen: false,
    };
  };
  
  const sidebarReducer = (state = initialState, action) => {
    switch (action.type) {
      case OPEN_SIDEBAR:
        return { ...state, menuOpen: true };
        /* falls through */
  
      case CLOSE_SIDEBAR:
        return { ...state, menuOpen: false };
        /* falls through */
  
      default:
        return state;
    }
  };
  
  export default sidebarReducer;